import { Injectable, inject } from '@angular/core';
import { Subject } from 'rxjs';
import { ToastAbstraction } from '@common/ports/abstractions';
import { ToastInterface } from '@common/ports/interfaces/toast.interface';

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  private _aMsg = inject(ToastAbstraction);

  public notificationChange: Subject<ToastInterface> = this._aMsg.notificationChange;
}
