import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class ImgService {

    // Función para convertir base64 a File
    base64ToFile(base64: string, filename: string, mimeType: string = ''): File {
        const blob = this.base64ToBlob(base64, mimeType);
        return new File([blob], filename, { type: mimeType });
    }

    // Función para convertir base64 a Blob
    private base64ToBlob(base64: string, mimeType: string = ''): Blob {
        const byteString = atob(base64.split(',')[1]);
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);

        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        return new Blob([ab], { type: mimeType });
    }
}
