import { computed, inject, Injectable, signal } from "@angular/core";
import { lastValueFrom } from "rxjs";

import { ListHelperInterface, ListInterface, UserInterface } from "@common/ports/interfaces";
import { ListAbstraction } from "@common/ports/abstractions";
import { ListHelper } from "@common/domain/helpers";

import { CityInterface } from "@city/ports/interfaces";
import { ClientInterface } from "@clients/ports/interfaces";
import { CountryInterface } from "@countries/ports/interfaces";
import { DeliveryTypeInterface } from "@delivery-type/ports/interfaces";
import { FormPaymentInterface } from "@form-payment/ports/interfaces";
import { InstitutionInterface } from "@institution/ports/interfaces";
import { ProductServiceInterface } from "@products-services/ports/interfaces";
import { RolPermissionInterface } from "@roles-permissions/ports/interfaces";
import { SaleInterface } from "@sales/ports/interfaces";
import { StateInterface } from "@state/ports/interfaces";

@Injectable({
  providedIn: 'root'
})
export class ListServiceAsync {
  private listHelper: ListHelperInterface = new ListHelper();

  private listAbstraction = inject(ListAbstraction);

  private countCityTemp = signal<number>(0);
  private countClientTemp = signal<number>(0);
  private countCountryTemp = signal<number>(0);
  private countDeliveryTypeTemp = signal<number>(0);
  private countInstitutionTemp = signal<number>(0);
  private countPaidTemp = signal<number>(0);
  private countProductTemp = signal<number>(0);
  private countRolTemp = signal<number>(0);
  private countSaleTemp = signal<number>(0);
  private countStateTemp = signal<number>(0);
  private countUserTemp = signal<number>(0);

  private listCityTemp = signal<CityInterface[]>([]);
  private listClientTemp = signal<ClientInterface[]>([]);
  private listCountryTemp = signal<CountryInterface[]>([]);
  private listDeliveryTypeTemp = signal<DeliveryTypeInterface[]>([]);
  private listInstitutionTemp = signal<InstitutionInterface[]>([]);
  private listPaidTemp = signal<FormPaymentInterface[]>([]);
  private listProductTemp = signal<ProductServiceInterface[]>([]);
  private listRolTemp = signal<RolPermissionInterface[]>([]);
  private listSaleTemp = signal<SaleInterface[]>([]);
  private listStateTemp = signal<StateInterface[]>([]);
  private listTypeDocumentTemp = signal<ListInterface[]>([]);
  private listUserTemp = signal<UserInterface[]>([]);

  public isBtnSearchCity = signal<boolean>(false);
  public isBtnSearchClient = signal<boolean>(false);
  public isBtnSearchCountry = signal<boolean>(false);
  public isBtnSearchDeliveryType = signal<boolean>(false);
  public isBtnSearchInstitution = signal<boolean>(false);
  public isBtnSearchPaid = signal<boolean>(false);
  public isBtnSearchProduct = signal<boolean>(false);
  public isBtnSearchRol = signal<boolean>(false);
  public isBtnSearchSale = signal<boolean>(false);
  public isBtnSearchState = signal<boolean>(false);
  public isBtnSearchUser = signal<boolean>(false);

  public btnSearchCity = signal<number>(10);
  public btnSearchClient = signal<number>(10);
  public btnSearchCountry = signal<number>(10);
  public btnSearchDeliveryType = signal<number>(10);
  public btnSearchInstitution = signal<number>(10);
  public btnSearchPaid = signal<number>(10);
  public btnSearchProduct = signal<number>(10);
  public btnSearchRol = signal<number>(10);
  public btnSearchSale = signal<number>(10);
  public btnSearchState = signal<number>(10);
  public btnSearchUser = signal<number>(10);

  private institution = signal<InstitutionInterface>({} as InstitutionInterface);

  public countCity = computed(() => this.countCityTemp());
  public countClient = computed(() => this.countClientTemp());
  public countCountry = computed(() => this.countCountryTemp());
  public countDeliveryType = computed(() => this.countDeliveryTypeTemp());
  public countInstitution = computed(() => this.countInstitutionTemp());
  public countPaid = computed(() => this.countPaidTemp());
  public countProduct = computed(() => this.countProductTemp());
  public countRol = computed(() => this.countRolTemp());
  public countSale = computed(() => this.countSaleTemp());
  public countState = computed(() => this.countStateTemp());
  public countUser = computed(() => this.countUserTemp());

  public listCity = computed(() => this.listCityTemp());
  public listClient = computed(() => this.listClientTemp());
  public listCountry = computed(() => this.listCountryTemp());
  public listDeliveryType = computed(() => this.listDeliveryTypeTemp());
  public listInstitution = computed(() => this.listInstitutionTemp());
  public listPaid = computed(() => this.listPaidTemp());
  public listProduct = computed(() => this.listProductTemp());
  public listRol = computed(() => this.listRolTemp());
  public listSale = computed(() => this.listSaleTemp());
  public listState = computed(() => this.listStateTemp());
  public listTypeDocument = computed(() => this.listTypeDocumentTemp());
  public listUser = computed(() => this.listUserTemp());

  setInstitution(institution: InstitutionInterface) {
    this.institution.update(() => institution);
  }

  async getCity(): Promise<void> {
    try {
      const limit = this.listHelper.calculateLimit(this.isBtnSearchCity(), this.btnSearchCity());

      let query = this.listHelper.buildQueryString(1, limit, null, 'name');

      query += `&isActive=true`;

      const resp = await lastValueFrom(this.listAbstraction.listCity(query));
      this.listCityTemp.update(() => (resp && resp.data.length > 0) ? resp.data[0].data : []);
      this.countCityTemp.update(() => (resp && resp.data.length > 0) ? resp.data[0].count : 0);
    } catch (error) {
      this.listHelper.handleError(error);
    }
  }

  async getClient(): Promise<void> {
    try {
      const limit = this.listHelper.calculateLimit(this.isBtnSearchClient(), this.btnSearchClient());
      this.btnSearchClient.update(() => limit);

      let query = this.listHelper.buildQueryString(1, limit, null, 'name');

      query += `&active=true`;

      const resp = await lastValueFrom(this.listAbstraction.listClient(query));
      this.listClientTemp.update(() => (resp && resp.data.length > 0) ? resp.data[0].data : []);
      this.countClientTemp.update(() => (resp && resp.data.length > 0) ? resp.data[0].count : 0);
    } catch (error) {
      this.listHelper.handleError(error);
    }
  }

  async getCountry(): Promise<void> {
    try {
      const limit = this.listHelper.calculateLimit(this.isBtnSearchCountry(), this.btnSearchCountry());

      let query = this.listHelper.buildQueryString(1, limit, null, 'name');

      query += `&isActive=true`;

      const resp = await lastValueFrom(this.listAbstraction.listCountry(query));
      this.listCountryTemp.update(() => (resp && resp.data.length > 0) ? resp.data[0].data : []);
      this.countCountryTemp.update(() => (resp && resp.data.length > 0) ? resp.data[0].count : 0);
    } catch (error) {
      this.listHelper.handleError(error);
    }
  }

  async getDeliveryType(): Promise<void> {
    try {
      const limit = this.listHelper.calculateLimit(this.isBtnSearchDeliveryType(), this.btnSearchDeliveryType());

      let query = this.listHelper.buildQueryString(1, limit, null, 'name');

      query += `&isActive=true`;

      const resp = await lastValueFrom(this.listAbstraction.listDeliveryType(query));
      this.listDeliveryTypeTemp.update(() => (resp && resp.data.length > 0) ? resp.data[0].data : []);
      this.countDeliveryTypeTemp.update(() => (resp && resp.data.length > 0) ? resp.data[0].count : 0);
    } catch (error) {
      this.listHelper.handleError(error);
    }
  }

  async getInstitution(): Promise<void> {
    try {
      const limit = this.listHelper.calculateLimit(this.isBtnSearchInstitution(), this.btnSearchInstitution());

      let query = this.listHelper.buildQueryString(1, limit, null, 'name');

      query += `&isActive=true`;

      const resp = await lastValueFrom(this.listAbstraction.listInstitution(query));
      this.listInstitutionTemp.update(() => (resp && resp.data.length > 0) ? resp.data[0].data : []);
      this.countInstitutionTemp.update(() => (resp && resp.data.length > 0) ? resp.data[0].count : 0);
    } catch (error) {
      this.listHelper.handleError(error);
    }
  }

  async getPaid(): Promise<void> {
    try {
      const limit = this.listHelper.calculateLimit(this.isBtnSearchPaid(), this.btnSearchPaid());

      let query = this.listHelper.buildQueryString(1, limit, null, 'name');

      query += `&isActive=true`;

      const resp = await lastValueFrom(this.listAbstraction.listPaid(query));
      this.listPaidTemp.update(() => (resp && resp.data.length > 0) ? resp.data[0].data : []);
      this.countPaidTemp.update(() => (resp && resp.data.length > 0) ? resp.data[0].count : 0);
    } catch (error) {
      this.listHelper.handleError(error);
    }
  }

  async getProduct(): Promise<void> {
    try {
      const limit = this.listHelper.calculateLimit(this.isBtnSearchProduct(), this.btnSearchProduct());

      let query = this.listHelper.buildQueryString(1, limit, null, 'name');

      query += `&isActive=true`;
      if (this.institution().id) query += `&institutionId=${this.institution().id}`;

      const resp = await lastValueFrom(this.listAbstraction.listProduct(query));
      this.listProductTemp.update(() => (resp && resp.data.length > 0) ? resp.data[0].data : []);
      this.countProductTemp.update(() => (resp && resp.data.length > 0) ? resp.data[0].count : 0);
    } catch (error) {
      this.listHelper.handleError(error);
    }
  }

  async getRol(): Promise<void> {
    try {
      const limit = this.listHelper.calculateLimit(this.isBtnSearchRol(), this.btnSearchRol());

      let query = this.listHelper.buildQueryString(1, limit, null, 'name');

      const resp = await lastValueFrom(this.listAbstraction.listRol(query));
      this.listRolTemp.update(() => (resp && resp.data.length > 0) ? resp.data[0].data : []);
      this.countRolTemp.update(() => (resp && resp.data.length > 0) ? resp.data[0].count : 0);
    } catch (error) {
      this.listHelper.handleError(error);
    }
  }

  async getSale(): Promise<void> {
    try {
      const limit = this.listHelper.calculateLimit(this.isBtnSearchSale(), this.btnSearchSale());

      let query = this.listHelper.buildQueryString(1, limit, null, 'code');

      const resp = await lastValueFrom(this.listAbstraction.listSale(query));
      this.listSaleTemp.update(() => (resp && resp.data.length > 0) ? resp.data[0].data : []);
      this.countSaleTemp.update(() => (resp && resp.data.length > 0) ? resp.data[0].count : 0);
    } catch (error) {
      this.listHelper.handleError(error);
    }
  }

  async getState(): Promise<void> {
    try {
      const limit = this.listHelper.calculateLimit(this.isBtnSearchState(), this.btnSearchState());

      let query = this.listHelper.buildQueryString(1, limit, null, 'name');

      const resp = await lastValueFrom(this.listAbstraction.listState(query));
      this.listStateTemp.update(() => (resp && resp.data.length > 0) ? resp.data[0].data : []);
      this.countStateTemp.update(() => (resp && resp.data.length > 0) ? resp.data[0].count : 0);
    } catch (error) {
      this.listHelper.handleError(error);
    }
  }

  async getTypeDocument(): Promise<void> {
    try {
      const resp = await lastValueFrom(this.listAbstraction.listTypeDocument());
      this.listTypeDocumentTemp.update(() => (resp && resp.data.length > 0) ? resp.data[0].data : []);
    } catch (error) {
      this.listHelper.handleError(error);
    }
  }

  async getUser(): Promise<void> {
    try {
      const limit = this.listHelper.calculateLimit(this.isBtnSearchUser(), this.btnSearchUser());

      let query = this.listHelper.buildQueryString(1, limit, null, 'firstName');

      query += `&active=true`;

      const resp = await lastValueFrom(this.listAbstraction.listUser(query));
      this.listUserTemp.update(() => (resp && resp.data.length > 0) ? resp.data[0].data : []);
      this.countUserTemp.update(() => (resp && resp.data.length > 0) ? resp.data[0].count : 0);
    } catch (error) {
      this.listHelper.handleError(error);
    }
  }
}
