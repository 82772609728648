import { Injectable, inject } from '@angular/core';
import { LoadingAbstraction } from '@common/ports/abstractions/loading/loading.abstraction';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  private _Loading = inject(LoadingAbstraction);

  getLoading() {
    const isLoading$ = this._Loading.isLoading$
    return isLoading$
  }

}
